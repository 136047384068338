'use client';

import { Button, Input } from '@/components/ui';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNhostClient } from '@nhost/nextjs';
import * as Sentry from '@sentry/nextjs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import * as z from 'zod';
const formSchema = z.object({
  email: z.string().email('Invalid email address').refine(email => {
    const hasPlus = email.includes('+');
    const isSyncDomain = email.endsWith('sync.so');
    return !hasPlus || hasPlus && isSyncDomain;
  }, {
    message: 'Email aliases are not allowed'
  })
});
export const MagicLinkSignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const nhostClient = useNhostClient();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  });
  const handleSignInWithEmail = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    try {
      await nhostClient.auth.signIn({
        email: values.email,
        options: {
          redirectTo: `${window.location.origin}/?action=login&provider=email`
        }
      });
      toast.success('Check your email', {
        description: 'We sent a login link to your email'
      });
    } catch (error) {
      Sentry.captureException(error);
      toast.error('Failed to send login link. Please try again.');
    } finally {
      form.reset();
      setIsLoading(false);
    }
  };
  return <Form {...form} data-sentry-element="Form" data-sentry-component="MagicLinkSignIn" data-sentry-source-file="login-with-magic-link-form.tsx">
      <form className="flex flex-col gap-4 w-full" onSubmit={form.handleSubmit(handleSignInWithEmail)}>
        <FormField control={form.control} name="email" disabled={isLoading} render={({
        field
      }) => <FormItem>
              <FormControl>
                <Input placeholder="enter your email" type="email" {...field} size="lg" />
              </FormControl>
              <FormMessage />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="login-with-magic-link-form.tsx" />
        <Button variant="secondary" type="submit" disabled={isLoading} size="lg" data-sentry-element="Button" data-sentry-source-file="login-with-magic-link-form.tsx">
          {isLoading ? 'Sending...' : 'Continue'}
        </Button>
      </form>
    </Form>;
};