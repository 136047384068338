import { activeInvitationCodeAtom } from '@/app/(protected-without-nav)/_atoms';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useAtom } from 'jotai';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

const AUTH_ROUTES = ['/login', '/signup'];

export default function useAuthRedirect() {
  const router = useRouter();
  const pathname = usePathname();
  const { isAuthenticated, isLoading } = useAuthContext();
  const [activeInvitationCode, setActiveInvitationCode] = useAtom(
    activeInvitationCodeAtom,
  );

  useEffect(() => {
    if (!isLoading) {
      const isAuthRoute = AUTH_ROUTES.includes(pathname);

      const pathParts = pathname?.split('/');
      const queryParam =
        pathParts?.length === 3 && pathParts?.[1] === 'invite'
          ? `redirectUrl=/invite/${pathParts[2]}`
          : null;
      if (
        pathParts?.length === 3 &&
        pathParts?.[1] === 'invite' &&
        !isAuthenticated
      ) {
        setActiveInvitationCode(pathParts[2]);
      }

      if (!isAuthenticated && !isAuthRoute) {
        return router.push('/login' + (queryParam ? `?${queryParam}` : ''));
      }
      if (isAuthenticated && activeInvitationCode) {
        return router.push(`/invite/${activeInvitationCode}`);
      }
      if (isAuthenticated && isAuthRoute) {
        return router.push('/');
      }
    }
  }, [isAuthenticated, isLoading, router, pathname, activeInvitationCode]);

  return { isLoading, isAuthenticated };
}
