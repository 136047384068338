'use client';

import { FC, useEffect, useState } from 'react';
import { FaGithub as GithubIcon, FaGoogle as GoogleIcon } from 'react-icons/fa';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Muted, P } from '../ui';
import { MagicLinkSignIn } from './login-with-magic-link-form';
import LoginWithProviderButton from './login-with-provider-button';
interface SignInFormProps {
  showRedirectLink?: boolean;
}
export const SignInForm: FC<SignInFormProps> = ({
  showRedirectLink = false
}) => {
  const pathname = usePathname();
  const [path, setPath] = useState<string | null>(null);
  const redirectTo = path === '/login' ? '/signup' : '/login';
  const redirectText = path === '/login' ? "don't have an account?" : 'already have an account?';
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);
  return <div className="w-full flex flex-col items-center gap-2" data-sentry-component="SignInForm" data-sentry-source-file="sign-in-form.tsx">
      <LoginWithProviderButton provider="google" icon={<GoogleIcon />} data-sentry-element="LoginWithProviderButton" data-sentry-source-file="sign-in-form.tsx" />
      <LoginWithProviderButton provider="github" icon={<GithubIcon />} data-sentry-element="LoginWithProviderButton" data-sentry-source-file="sign-in-form.tsx" />
      <P className="text-secondary-foreground" data-sentry-element="P" data-sentry-source-file="sign-in-form.tsx">or</P>
      <MagicLinkSignIn data-sentry-element="MagicLinkSignIn" data-sentry-source-file="sign-in-form.tsx" />

      {showRedirectLink && <Link href={redirectTo} className="mt-2">
          <Muted className="text-sm">{redirectText}</Muted>
        </Link>}
    </div>;
};