import { CircleHelpIcon } from 'lucide-react';
import Link from 'next/link';
import { Button } from './button';
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';
const tooltipConfig = {
  sideOffset: 20,
  message: `email us if you need help`
};
const SupportButton = () => {
  return <Tooltip data-sentry-element="Tooltip" data-sentry-component="SupportButton" data-sentry-source-file="support-button.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="support-button.tsx">
        <Link href="mailto:hello@sync.so" target="_blank" data-sentry-element="Link" data-sentry-source-file="support-button.tsx">
          <Button size="icon" data-sentry-element="Button" data-sentry-source-file="support-button.tsx">
            <CircleHelpIcon className="size-6" data-sentry-element="CircleHelpIcon" data-sentry-source-file="support-button.tsx" />
          </Button>
        </Link>
      </TooltipTrigger>
      <TooltipContent sideOffset={tooltipConfig.sideOffset} data-sentry-element="TooltipContent" data-sentry-source-file="support-button.tsx">
        {tooltipConfig.message}
      </TooltipContent>
    </Tooltip>;
};
export default SupportButton;