import { Logo } from '@/components/logo';
import { H3 } from '@/components/ui';
import { FC } from 'react';
import { AuthConsent } from './auth-consent';
interface AuthContentProps {
  title: string;
}
export const AuthContent: FC<AuthContentProps> = ({
  title
}) => {
  return <div className="flex flex-col gap-2 w-full" data-sentry-component="AuthContent" data-sentry-source-file="auth-content.tsx">
      <Logo size="lg" data-sentry-element="Logo" data-sentry-source-file="auth-content.tsx" />
      <H3 className="pt-4" data-sentry-element="H3" data-sentry-source-file="auth-content.tsx">{title}</H3>
      <AuthConsent data-sentry-element="AuthConsent" data-sentry-source-file="auth-content.tsx" />
    </div>;
};