'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC, useEffect, useState } from 'react';
import { Muted } from '../ui/typography';
interface AuthConsentProps {
  actionText?: string;
}
export const AuthConsent: FC<AuthConsentProps> = () => {
  const pathname = usePathname();
  const [path, setPath] = useState<string | null>(null);
  const actionText = path === '/login' ? 'signing in' : 'signing up';
  useEffect(() => {
    setPath(pathname);
  }, [pathname]);
  return <Muted data-sentry-element="Muted" data-sentry-component="AuthConsent" data-sentry-source-file="auth-consent.tsx">
      By {actionText}, you consent and agree to our
      <br />
      <Link href="https://sync.so/terms" target="_blank" rel="noopener noreferrer" className="underline" data-sentry-element="Link" data-sentry-source-file="auth-consent.tsx">
        terms of service
      </Link>{' '}
      and{' '}
      <Link href="https://sync.so/privacy" target="_blank" rel="noopener noreferrer" className="underline" data-sentry-element="Link" data-sentry-source-file="auth-consent.tsx">
        privacy policy
      </Link>
      .
    </Muted>;
};