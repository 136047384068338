'use client';

import { FC, ReactElement } from 'react';
import { Button } from '@/components/ui';
import { useNhostClient } from '@nhost/nextjs';
import * as Sentry from '@sentry/nextjs';
interface Props {
  provider: any | undefined;
  icon: ReactElement;
  label?: string;
}

// TODO: Update redirect depending on how they got here
// Pass in redirect url as prop
const LoginWithProviderButton: FC<Props> = ({
  provider,
  icon,
  label
}) => {
  const nhostClient = useNhostClient();
  const handleSignIn = () => {
    if (!nhostClient || !nhostClient.auth) {
      Sentry.captureException(new Error('Nhost client or auth is not initialized'));
      return;
    }
    nhostClient.auth.signIn({
      provider,
      options: {
        redirectTo: `${window.location.origin}/?action=login&provider=${provider}`
      }
    });
  };
  return <Button onClick={handleSignIn} className="w-full" leftIcon={icon} variant="outline" size="lg" data-sentry-element="Button" data-sentry-component="LoginWithProviderButton" data-sentry-source-file="login-with-provider-button.tsx">
      {label || `continue with ${provider}`}
    </Button>;
};
export default LoginWithProviderButton;